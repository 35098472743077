import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player/lazy";
import * as styles from "./BackgroundVideo.module.scss";

const BackgroundVideo = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <div className={styles.background}>
      <div className={styles.vimeoWrapper}>
        {/* <ReactPlayer
      // url="https://vimeo.com/597942332"
      url="https://vimeo.com/600924856/26bbb26a1f"
      width="100%"
      height="100vh"
      controls={false}
      loop={true}
      playing={true}
      muted={true}
      config={{
        vimeo: {
          background: true,
        },
      }}
    /> */}
        {/* raindrop: 600924856 */}
        {/* moon: 673129074 */}
        {/* changwon: 755113805 */}
        {/* standardstrings: 753012065 */}
        {/* in winter i will fly: 753007168 */}
        {/* walk through biennale 758408395 */}

        {loaded && (
          <iframe
            src="https://player.vimeo.com/video/758408395?api=1&background=1&autoplay=1&muted=1&loop=1"
            allowFullScreen="allowfullscreen"
            frameBorder="0"
            loading="lazy"
            title="background artwork video"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default BackgroundVideo;
